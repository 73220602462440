import Vue from "vue";
import axios from "axios";
import axiosRetry from "axios-retry";
import store from "@/store/index";

const apiAccounts = axios.create({
  baseURL: `${Vue.prototype.$Settings.all.accountsApi.url}/useraccounts`,
  withCredentials: true, //true para soportar cookies de respuests
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization-Middleware": `Basic ${Vue.prototype.$Settings.all.jwt}`,
    "Execution-Mode": `${Vue.prototype.$Settings.all.excecutionMode}`,
  },
});

axiosRetry(apiAccounts, {
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

apiAccounts.interceptors.request.use((configuration) => {
  const currentUserAccount = Vue.prototype.$Authentication.getAccount();
  if (currentUserAccount) {
    configuration.headers.CurrentUserToken = currentUserAccount.token;
  }
  return configuration;
});

apiAccounts.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    if (error.response && error.response.status == 500) {
      if (error.response.config.url!="/sendOtp")
      store.dispatch(
        "notifications/addError",
        "Servicio no disponible. Vuelva a intentar"
      );
    }

    if (
      (error.response && error.response.status == 401) ||
      (error.response && error.response.status == 403)
    ) {
      store.dispatch("currentUser/logout");

      store.dispatch("notifications/addInfo", "Su sesión ha expirado");
      store.dispatch("notifications/setMiddleware");
    }

    return Promise.reject(error);
  }
);

export default {
  checkIsValidPasswordPattern(password) {
    return apiAccounts.post("/CheckIsValidPasswordPattern", {
      password: password,
    });
  },
  getByIdentification(number) {
    return apiAccounts.post("/Information", {
      identificationNumber: btoa(number),
    });
  },
  exists(number) {
    return apiAccounts.get(`/Exists/${number}`);
  },
  getByUserTypeAndIdentification(userType, number) {
    return apiAccounts.get(
      `/ByUserTypeAndIdentification/${userType}/${number}`
    );
  },
  getById(id) {
    return apiAccounts.get(`/ById/${id}`);
  },
  getByRole(roleId) {
    return apiAccounts.get(`/ByRole/${roleId}`);
  },
  create(accountData) {
    return apiAccounts.post("/", accountData);
  },
  update(id, accountData) {
    return apiAccounts.put(`/${id}`, accountData);
  },
  forceChangePassword(passwordData) {
    return apiAccounts.post("/ForceChangePassword", passwordData);
  },
  sendOtp(number) {
    return apiAccounts.post(`/sendOtp`, {
      identificationNumber: number,
    });
  },
  verifyOtp(verifyOtpData) {
    return apiAccounts.post("/verifyOtp", verifyOtpData);
  },
};
